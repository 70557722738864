import Header from './components/Header';
import React from "react";
import Footer from './components/Footer';
import FloatingCallButton from './components/FloatingCallButton';
const AppShell = ({ children }) => {
    return (
        <>
            <Header />
            <FloatingCallButton />
            {children}
            <Footer />
        </>
    );
}

export default AppShell;