import { Box } from "@mui/material";
import React from "react";
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from "react-swipeable-views";
import home1 from "../images/resturant/home1.jpg";
import home2 from "../images/resturant/home2.jpg";
import home3 from "../images/resturant/home3.jpg";
import home4 from "../images/resturant/home4.jpg";
import home5 from "../images/resturant/home5.jpg";

const items = [
    {
        src: home1,
        description: "Probably the most random thing you have ever seen!",
    },
    {
        src: home2,
        description: "Hello World!",
    },
    {
        src: home3,
        description: "Hello World!",
    },
    {
        src: home4,
        description: "Hello World!",
    },
    {
        src: home5,
        description: "Hello World!",
    },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CarouselHome = () => {
    return (
        <AutoPlaySwipeableViews enableMouseEvents
            style={{
                overflow: "hidden",
            }}
        >
            {items.map((item, i) => (
                <Box
                    component={"img"}
                    key={i}
                    src={item.src}
                    alt="restaurant"
                    height={"auto"}
                    width={"100%"}
                />
            ))}
        </AutoPlaySwipeableViews>
    );
};

export default CarouselHome;
