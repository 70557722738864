import { Box, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';
import React from 'react'
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from "react-swipeable-views";
import { useTheme } from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function CarouselPlate(props) {
    const theme = useTheme();
    const matchessm = useMediaQuery(theme.breakpoints.up('sm'));
    const matcheslg = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Box>
            <AutoPlaySwipeableViews  enableMouseEvents>
                {props.items.map((e, i) => (
                    <ImageList key={i} cols={matcheslg?3:matchessm?2:1}>
                        {e.map((item, y) => (
                            <ImageListItem
                                key={y}
                                height={"auto"}
                                width={"auto"}
                                
                            >
                                <img
                                    alt="restaurant"
                                    src={item.src}
                                    loading="lazy"
                                >
                                </img>
                                <ImageListItemBar
                                    title={item.name}
                                    subtitle={item.description}
                                    position="top"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                ))}
            </AutoPlaySwipeableViews>
        </Box>
    )
}

export default CarouselPlate