import React from "react";
import EmailForm from "../components/EmailForm";
import CarouselPlate from "../components/CarouselPlate";
import CarouselHome from "../components/CarouselHome";
import { Stack, Typography, ImageList, ImageListItem, ImageListItemBar, useMediaQuery, useTheme, /*Box, Button*/ } from "@mui/material";
import "@fontsource/abel"
import plate1 from "../images/piatti/IMG_8179.JPG";
import plate2 from "../images/piatti/Ristorante (45).JPG";
import plate3 from "../images/piatti/YHEX5082.JPG";
import plate4 from "../images/piatti/VEPU1654.JPG";
import plate5 from "../images/piatti/393.JPG";
import plate6 from "../images/piatti/Ristorante (241).JPG";
import plate7 from "../images/piatti/tubetto con nero di seppia.jpg";
import plate8 from "../images/piatti/Polpo con crema di ceci e nero di seppia.jpg";
import plate9 from "../images/piatti/Risotto con zucca e sarda al vapore.jpg";
//import plate10 from "../images/piatti/Parmigiana di paccheri e branzino.jpg";
//import plate11 from "../images/piatti/Tartare di Palamito.jpg";
//import plate12 from "../images/piatti/Spigola cruda e tartare di gamberi.jpg";
import Title from "../components/Title";
import vip1 from "../images/vips/Roberto giacobbo.JPG"
import vip2 from "../images/vips/Sergio castellitto.JPG"
import vip3 from "../images/vips/teo mammuccari.JPG"
import vip4 from "../images/vips/Mara carfagna.jpg"
import vip5 from "../images/vips/beatrice lorenzin.JPG"
import vip6 from "../images/vips/Federico salvatore.JPG"
import vip7 from "../images/vips/gino paoli.JPG"
import vip8 from "../images/vips/ALESSANDRO BOLIDE.JPG"
import vip9 from "../images/vips/ettore bassi.JPG"
import vip10 from"../images/vips/nicola acunzo.JPG"
import vip11 from"../images/vips/Rosaria de cicco e antonio onorato.JPG"
import vip12 from"../images/vips/TONINO CAROTONE.JPG"
import vip13 from"../images/vips/Roccohunt.jpg"
import vip14 from"../images/vips/Francesca Michelin.jpg"
import vip15 from"../images/vips/StefanoBollani.jpg"
import src from "../images/oldSite/ristorante1.jpg"
import src1 from "../images/oldSite/ristorante2.jpg"
import src2 from "../images/oldSite/ristorante3.jpg"
//import ilmandorlo from "../images/oldSite/IMG_9475.jpg"
//import ilmandorlologo from "../images/oldSite/il-mandorlo-logo.png"
const plate = [
    [
        {
            src: plate1,
            name: "Alici Imbottite",
            description: "",
        },
        {
            src: plate2,
            name: "Carpaccio di polpo",
            description: "",
        },
        {
            src: plate3,
            name: "Pacchero con scorfano",
            description: "",
        }
    ],
    [
        {
            src: plate4,
            name: "Linguine con Aragosta",
            description: "",
        },
        {
            src: plate5,
            name: "Il Crudo",
            description: "",
        },
        {
            src: plate6,
            name: "Aragosta alla catalana",
            description: "",
        }
    ],
    [
        {
            src:plate7,
            name: "Tubetto al nero di seppia",
            description: "",
        },
        {
            src:plate8,
            name: "Polpo con crema di ceci e nero di seppia",
            description: "",

        },
        {
            src:plate9,
            name: "Risotto con zucca e sarda al vapore",
            description: "",

        },
    ],
    /*[
        {
            src:plate10,
            name: "Parmigiana di paccheri e branzino",
            description: "",

        },
        {
            src:plate11,
            name: "Tartare di Palamito",
            description: "",

        },
        {
            src:plate12,
            name: "Spigola cruda e tartare di gamberi",
            description: "",
        }

    ]*/
];
const vip = [
    [
        {
            src: vip1,
            name: "Roberto Giacobbo",
            description: ""
        },
        {
            src: vip2,
            name: "Sergio Castellitto",
            description: "",
        },
        {
            src: vip3,
            name: "Teo Mammuccari",
            description: "",
        },
    ],
    [
        {
            src: vip4,
            name: "Mara Carfagna",
            description: "",
        },
        {
            src: vip5,
            name: "Beatrice Lorenzin",
            description: "",
        },
        {
            src: vip6,
            name: "Federico Salvatore",
            description: "",
        }
    ],
    [
        {
            src: vip7,
            name: "Gino Paoli",
            description: "", 
        },
        {
            src: vip8,
            name: "Alessandro Bolide",
            description: "",
        },
        {
            src: vip9,
            name: "Ettore Bassi",
            description: "",
        }
    ],
    [
        {
            src: vip10,
            name: "Nicola Acunzo",
            description: "",
        },
        {
            src: vip11,
            name: "Rosario de Cicco e Antonio Onorato",
            description: "",
        },
        {
            src: vip12,
            name: "Tonino Carotone",
            description: "",
        }

    ],
    [
        {
            src: vip13,
            name: "Rocco Hunt",
            description: "",
        },
        {
            src: vip14,
            name: "Francesca Michelin",
            description: "",
        },
        {
            src: vip15,
            name: "Stefano Bollani",
            description: "",
        },

    ]



];
const HomePage = () => {
    const theme = useTheme();
    const matchessm = useMediaQuery(theme.breakpoints.up('sm'));
    const matcheslg = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Stack>
            <CarouselHome />

            <Title title="Chi siamo" to="chisiamo"/>
            <Typography variant={"h4"} sx={{ textAlign: "center", marginX: "5em", marginTop: "2em", fontFamily: "Abel", fontSize:{xs:"1em"} }}  >
                Antonio e Giusy, Mamma Angelina, Salvatore chef e i ragazzi in sala, vi garantiscono la qualita' e la genuinita' dei piatti preparati con passione e professionalita' esclusivamente con pesce fresco che arriva quotidianamente dal pescato di Zio Claudio, rispettando le stagioni per l 'uso di frutta e verdura e adoperando unicamente l 'olio extra vergine di oliva prodotto da zia Luisa. Per quanto riguarda l 'impasto nell 'imbottitura delle alici, nelle polpettine di pesce, esclusivita' di mamma Angelina, e di molti altri piatti preparati con cura e maestria dallo chef, ci serviamo di uova freschissime e formaggi tipici cilentani di nostra produzione scrupolosamente selezionati al fine di farvi scoprire e gustare in ogni momento dell'anno le prelibatezze della Dieta Mediterranea che si vanno a mescolare con i sapori della tradizione cilentana.
                Augurandovi buon appetito
            </Typography>
            <ImageList cols={matcheslg?3:matchessm?2:1}>
                <ImageListItem

                    height={"auto"}
                    width={"auto"}

                >
                    <img

                        alt="restaurant"
                        src={src}
                        loading="lazy"
                        height={"auto"}
                        width={"33%"}
                    >
                    </img>
                    <ImageListItemBar
                        title={<Typography>
                            <h3>
                                SUL CORSO PRINCIPALE
                            </h3>
                        </Typography>}
                        subtitle={<Typography>
                            In posizione centralissima, sul corso principale di Acciaroli.
                        </Typography>}
                        position="top"
                    />
                </ImageListItem>
                <ImageListItem

                    height={"auto"}
                    width={"auto"}

                >
                    <img

                        alt="restaurant"
                        src={src1}
                        loading="lazy"
                        height={"auto"}
                        width={"33%"}
                    >
                    </img>
                    <ImageListItemBar
                        title={<Typography>
                            <h3>
                                CURA DEI DETTAGLI
                            </h3>
                        </Typography>}
                        subtitle={<Typography>
                            La sala raffinata e dallo stile marinaro.
                        </Typography>}
                        position="top"
                    />
                </ImageListItem>
                <ImageListItem

                    height={"auto"}
                    width={"auto"}

                >
                    <img
                        alt="restaurant"
                        src={src2}
                        loading="lazy"
                        height={"auto"}
                        width={"33%"}
                    >
                    </img>
                    <ImageListItemBar
                        title={<Typography>
                            <h3>
                                LA CANTINA
                            </h3>
                        </Typography>}
                        subtitle={<Typography>
                            La cantina propone un' ampia scelta di vini Campani ed in particolare del Cilento.
                        </Typography>}
                        position="top"
                    />
                </ImageListItem>
            </ImageList>
            <Title title="Cucina" to="cucina" />
            <CarouselPlate items={plate} />
            <Typography variant={"h3"} sx={{ marginX: "5em", marginTop: "2em", fontFamily: "Abel", fontSize:{xs:"1.5em"} }}  >
                Presidio slow food!
            </Typography>
            <Typography variant={"h4"} sx={{ textAlign: "center", marginX: "5em", marginTop: "2em", fontFamily: "Abel", fontSize:{xs:"1em"}}}  >
                Le alici di Menaica,
                magre e delicate, dalla carne chiara tendente al rosa e dal profumo intenso e delicato, si pescano nelle giornate di mare calmo nel periodo di maggio e giugno con un'antica tecnica risalente alla Grecia classica. Oggi, tale tecnica sopravive solo in alcuni paesini del Cilento (Acciaroli e Pisciotta), utilizzando le reti sottili, dette appunto Menaica, che lasciano sfuggire i pesci più piccoli, selezionando quelli più grossi. I pescatori estraggono le alici dalle reti ad una ad una, eliminando la testa e interiora in modo da eliminare il sangue e le sistemano in cassette di legno senza usare il ghiaccio. Appena arrivate in porto, le alici di Menaica vengono lavate in salamoia e disposte in vasetti di terracotta detti terzigni, alternate a strati di sale. Quindi inizia la stagionatura per 3 mesi. Il risultato è un prodotto eccellente, di grande qualità e legato alla tradizione che è stato riconosciuto Presidio Slow Food.
            </Typography>
            <Title title="Chi ci ha scelto" to="chicihascelto" />
            <CarouselPlate items={vip} />
            {/* <Title title="Il Mandorlo" to="ilmandorlo" /> */}
            {// <Box sx={{ position: "relative" }}>
                // <Box sx={
                //     {
                //         flexDirection: "column",
                //         position: "absolute",
                //         width: "100%",
                //         height: "100%",
                //         display: "flex",
                //         justifyContent: "center",
                //         alignItems: "center",
                //     }
                // }>
                //     <img
                //         alt="restaurant"
                //         src={ilmandorlologo}
                //         loading="lazy"
                //         width={"30%"}
                //         style={{
                //             background: "white"
                //         }}
                //     >
                //     </img>
                //     <Button href="https://www.ilmandorloacciaroli.it/" sx={
                //         [
                //             {
                //                 marginTop: { sm: "1em", md: "3em" },
                //                 bgcolor: "#A8B59E",
                //                 color: "WHITE",
                //                 width: "50%",
                //                 fontSize: "2em"
                //             },
                //             {
                //                 "&:hover": {
                //                     color: "WHITE",
                //                     backgroundColor: "#6F7632",
                //                 },
                //             },
                //         ]
                //     }>
                //         VAI AL SITO 
                //     </Button>*/
                // </Box>
                // <img
                //     alt="restaurant"
                //     src={ilmandorlo}
                //     loading="lazy"
                //     width={"100%"}
                // >
                // </img>
            // </Box> 
            }
            <Title title="Contattaci" to="contattaci" />
            <EmailForm />
        </Stack >
    );
}
export default HomePage;