import "./App.css";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppShell from "./AppShell";
import HomePage from './pages/HomePage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppShell>
            <Outlet />
          </AppShell>
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/contacts" element={<HomePage />} />
      </Route>
    </Routes>
  );
};

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
