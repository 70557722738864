import React from 'react';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Collapse, IconButton, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        '& .MuiStack-root': {
            margin: theme.spacing(1),
            width: "50%",
            [theme.breakpoints.down("sm")]: {
                width: "80%",
            },
            [theme.breakpoints.down("md")]: {
                width: "65%",
            }
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
        '& .MuiBox-root': {
            justifyContent: 'space-evenly',
            display: 'flex'
        }
    },
}));

function EmailForm() {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const styles = useStyles();
    const { handleSubmit, control, reset } = useForm();

    const onSubmit = data => {
        fetch("http://localhost:8080/api/v1/email/send", {
            method: 'POST',
            mode: "cors",
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => { response.status === 200 ? setMessage("email inviata") : setMessage("errore nell'invio della mail"); setOpen(true) })
    }

    return (
        <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
            <Stack className='MuiStack-root'>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {message}
                    </Alert>
                </Collapse>
                <Controller
                    name='from'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label='Da'
                            variant='filled'
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete='email'
                        />
                    )}
                    rules={
                        {
                            required: 'from è richiesto',
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "email non valida"
                            }
                        }
                    }
                    defaultValue=""
                />
                <Controller
                    name='subject'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label='Oggetto'
                            variant='filled'
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'subject è richiesto' }}
                    defaultValue=""
                />
                <Controller
                    name='text'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                            label='Testo'
                            variant='filled'
                            multiline
                            rows={4}
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{ required: 'text è richiesto' }}
                    defaultValue=""
                />
                <Box>
                    <Button variant='contained' color='error' onClick={() => reset(
                        {
                            from: '',
                            subject: '',
                            text: ''
                        }
                    )}>CANCELLA</Button>
                    <Button type='submit' variant='contained' sx={{ bgcolor: "#000000" }}>Invia</Button>
                </Box>
            </Stack>
        </form>
    );
}

export default EmailForm;
