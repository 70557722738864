import { AppBar, IconButton, List, ListItem, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Box, } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import logo from "../images/logo bianco.png";

const socialIcon = [
    { icon: <InstagramIcon sx={{ color: 'white' }} />, href: 'https://www.instagram.com/ilrossoeilmare/' },
    { icon: <PhoneIcon color='error' />, href: 'tel:+39 3394956366' }
]

const item = [
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>ORARIO</Typography>,
        secondary: <Typography sx={{ color: "white", textAlign: "center" }}>Pranzo: 12:00/15:00  Cena: 19:00/23:00</Typography>
    },
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>Via Nicotera, 26</Typography>
    },
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>Acciaroli, Pollica (SA) 84068</Typography>
    },
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>Email: antonio.vassallo@live.it</Typography>
    },
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>cell: +39 3394956366</Typography>
    },
    {
        primary: <Typography variant='h6' sx={{ color: "white", textAlign: "center" }}>P.IVA: 04279770657</Typography>
    },
]

function Footer() {
    return (
        <AppBar
            position="static"
            color="primary"
            sx={
                {
                    backgroundColor: "black",
                    display: {
                        xs: "none",
                        md: "block"
                    },
                }
            }
        >
            <Toolbar sx={
                {
                    paddingTop: "2em",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    top: 'auto',
                    bottom: 0,
                }
            }>
                <Box>
                    <iframe
                        title="google map"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24386.650016176834!2d15.025412000000001!3d40.179441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb2eea77102b96f68!2sil%20rosso%20e%20il%20mare!5e0!3m2!1sit!2sit!4v1646251398453!5m2!1sit!2sit"
                        width="100%"
                        height="100%"
                        style={{border:0}}
                        allowfullscreen=""
                        loading="lazy"
                    >
                    </iframe>
                </Box>
                <Stack>
                    <img src={logo} alt={"LOGO"} width={"50%"} />
                    <List
                        sx={{ width: '50%', maxWidth: 360, bgcolor: "transparent", color: "white" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {
                            item.map((e, i) => {
                                return (
                                    <ListItem key={i}>
                                        <ListItemText
                                            primary={e.primary}
                                            secondary={e.secondary}
                                        />
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </Stack>
                <Box>
                    {socialIcon.map((e, i) => {
                        return <IconButton key={i} href={e.href}>
                            {e.icon}
                        </IconButton>
                    })}
                    <Typography variant="body1" color="inherit" >
                        &copy; 2022 HydraDev
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>

    )
}

export default Footer