import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
  ButtonGroup,
  Link,
  IconButton,
  Drawer,
  styled,
  Divider,
  List
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import logo from "../images/Logo finale 1.png";

const pages = [
  { name: "CHI SIAMO", href: "#chisiamo" },
  { name: "CUCINA", href: "#cucina" },
  /*{ name: "IL MANDORLO", href: "#ilmandorlo" },*/
  { name: "CHI CI HA SCELTO", href: "#chicihascelto" },
  { name: "Contatti e Info", href: "#contattaci" },
];

const Header = () => {
  const [navBackground, setNavBackground] = useState("transparent");
  const [navBackgroundText, setNavBackgroundText] = useState("black");
  const [borderBottom, setborderBottom] = useState("1px solid white");
  const [open, setOpen] = React.useState(false);
  const navRef = React.useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (show) {
        setNavBackground("rgba(0, 0, 0)");
        setNavBackgroundText("white");
        setborderBottom("none");
      } else {
        setNavBackground("transparent");
        setNavBackgroundText("black");
        setborderBottom("1px solid white");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawerWidth = 240;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  return (
    <>
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        backgroundColor: navBackground,
        boxShadow: "none",
        border: "none",
        borderBottom: borderBottom,
        transitionTimingFunction: "ease-in-out",
        WebkitTransitionDuration:"200ms",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: navBackground,
          transitionTimingFunction: "ease-in-out",
          WebkitTransitionDuration:"200ms",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: navBackground,
            justifyContent: "space-between"
          }}
        >
        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 2, display: { xs: "block", md: "none" }, color:"white",}} onClick={handleDrawerOpen}>
            <MenuIcon fontSize="inherit" sx={{fontSize:"2em"}} />
          </IconButton>
          <Link href={"#"}>
            <img src={logo} height={"100px"} alt={"LOGO"}></img>
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonGroup
              sx={{
                marginRight: "20px",
                border: "none",
                color: navBackgroundText,
                backgroundColor: navBackground,
                textDecoration: "none",
                transitionProperty:"none",
              }}
              underline="none"
            >
              {pages.map((item, i) => (
                <Button
                  href={item.href}
                  sx={[
                    {
                      "&:hover": {
                        color: "red",
                        backgroundColor: "white",
                        textDecoration: "none",
                      },
                    },
                    {
                      padding: "13px",
                      justifyContent: "center",
                      marginRight: "0",
                      backgroundColor: navBackground,
                      color: navBackgroundText,
                      boxShadow: "none",
                      textDecoration: "none",
                      fontWeight: "700",
                      fontSize: { sm: "12px", md: "15px", l: "16px" },
                      transitionProperty:"none",
                    },
                  ]}
                  key={i}
                  variant="contained"
                  underline="none"
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
          <Button
            sx={[
              {
                "&:hover": {
                  color: "red",
                  backgroundColor: "white",
                },
              },
              {
                padding: "8px",
                marginRight: "0",
                color: "#FF0000",
                border: "2px solid red",
                backgroundColor: navBackground,
                transitionProperty:"none",
                display: { xs: "none", md: "block" },
                fontWeight: "700",
                fontSize: { sm: "12px", md: "13px", l: "16px" }
              },
            ]}
            variant="contained"
            underline="none"
            href={"tel:+39 3394956366"}
          >
            Prenota un Tavolo
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
    <Drawer
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },
    }}
    variant="persistent"
    anchor="left"
    open={open}
  >
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>
      {pages.map((item, i) => (
        <ListItem key={i} disablePadding>
            <Button
                  href={item.href}
                  onClick={handleDrawerClose}
                  sx={[
                    {
                      "&:hover": {
                        color: "red",
                        backgroundColor: "white",
                        textDecoration: "none",
                      },
                    },
                    {
                      padding: "13px",
                      justifyContent: "center",
                      marginRight: "0",
                      backgroundColor: "transparent",
                      color: "black",
                      boxShadow: "none",
                      textDecoration: "none",
                      fontWeight: "700",
                      fontSize: { sm: "12px", md: "15px", l: "16px" },
                      transitionProperty:"none",
                    },
                  ]}
                  key={i}
                  variant="contained"
                  underline="none"
                >
                  {item.name}
                </Button>
        </ListItem>
      ))}
    </List>
  </Drawer>
  </>
  );
};

export default Header;
