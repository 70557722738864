import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material';
import logo from "../images/logotipo.svg";
function Title(props) {
    return (
        <Box id={props.to}
            sx={
                {
                    marginY: "2em",
                    marginX: "5em",
                    //maxWidth: "800px"
                }
            }
        >
            <img
                alt="restaurant"
                src={logo}
                loading="lazy"
            >
            </img>
            <Typography variant='h1'
                sx={
                    {
                        color:"#a51b0b",
                        fontFamily: "Abel",
                        textAlign: "center",
                        borderTop: "dotted 2px",
                        borderBottom: "dotted 2px",
                        fontSize:{xs: "3em"}
                    }
                }
            >
                {props.title}
            </Typography>
        </Box>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
}

export default Title
