import { Box, Fab, Button } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";

const FloatingCallButton = () => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        bottom: "18pt",
        display: "inline",
        height: "45pt",
        padding: "0",
        position: "fixed",
        left: "18pt",
        top: "auto",
        width: "45pt",
        zIndex: "2147483646",
      }}
    >
      <Fab
        aria-label="add"
        sx={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
      >
        <Button
          href={"tel:+39 3394956366"}
          sx={[
            {
              "&:hover": {
                color: "red",
              },
            },
            {
              color: "#FFFFFF",
            },
          ]}
          underline="none"
        >
          <CallIcon />
        </Button>
      </Fab>
    </Box>
  );
};

export default FloatingCallButton;
